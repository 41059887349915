import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";
import {
  cardMixin,
  innerMiddleMixin,
  innerCardLefttMixin,
  innerCardRightMixin,
} from "../mixin";

export const ParentGrid = styled.div`
  gap: 20px;
  margin: auto;
  width: max-content;
  grid-template-rows: repeat(3, 314px);
  display: grid;
  grid-template-columns: repeat(4, 314px);
  div {
    &:not(:last-child)::after {
      bottom: -15px !important;
    }
  }

  .modulo2x1 {
    //width: 642px;
  }
  ${breakpoints.tablet} {
    &.module-grid > div:after,
    &.module-grid > div:before {
      display: block !important;
    }
  }
  .div1 {
    grid-area: 1 / 1 / 3 / 3;
  }
  .div2 {
    grid-area: 3 / 1 / 3 / 1;
  }
  .div3 {
    grid-area: 3 / 2 / 4 / 3;
  }
  .div4 {
    grid-area: 1 / 4 / 2 / 4;
  }
  .div5 {
    grid-area: 2 / 4 / 2 / 4;
  }
  .div6 {
    grid-area: 4 / 4 / 3 / 4;
  }
  .div1,
  .div2 {
    ${breakpoints.desktop} {
      &:not(:last-child)::before {
        display: none;
      }
    }
  }

  .div3 {
    &:not(:last-child)::after {
      content: "";
      position: absolute;
      left: -11px;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: ${Color.greySeparator};
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          background-color: ${ColorDark.greySeparator};
        }
      }
    }
    &:not(:last-child)::before {
      right: -10px;
    }
    ${breakpoints.tablet} {
      &:not(:last-child)::before {
      }
    }
  }
  .div1,
  .div4,
  .div5,
  .div6 {
    ${innerMiddleMixin}
    &:before {
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          background-color: ${ColorDark.greySeparator}!important;
        }
      }
    }
    &:not(:last-child)::after {
      bottom: -7px !important;
    }
    &:not(:last-child)::before {
      display: none;
    }
  }
  .div1 {
    &:not(:last-child)::after {
      bottom: -9px !important;
    }
  }

  /*Estilos para Skeleton*/
  .div1.visual-skeleton {
    //height: 649px;
    ${breakpoints.phone} {
      height: auto;
    }
  }
  .div2.visual-skeleton {
    //height: 309px;
    ${breakpoints.phone} {
      height: auto;
    }
  }

  .div4.normal-skeleton,
  .div5.normal-skeleton,
  .div6.normal-skeleton {
    &:not(:last-child)::before {
      display: none;
    }
  }

  ${breakpoints.tablet} {
    grid-template-columns: repeat(3, 314px);
    grid-template-rows: repeat(4, 314px);
    .div2,
    .div1 {
      &:not(:last-child)::before {
        display: none !important;
      }
      &:not(:last-child)::after {
        background-color: ${Color.greySeparator} !important;
      }
    }
    .div1 {
      &:not(:last-child)::after {
        bottom: -7px !important;
      }
    }
    .div2 {
      &:not(:last-child)::after {
        bottom: -9px !important;
      }
    }
    .div3 {
      &:not(:last-child)::before {
        right: 0px;
        left: -11px;
      }
      &:not(:last-child)::after {
        width: 100% !important;
        background-color: ${Color.greySeparator} !important;
        top: auto !important;
        bottom: -9px !important;
      }
    }
    .div4,
    .div5 {
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: ${Color.greySeparator} !important;
        top: -9px;
        right: 0 !important;
      }
    }
    .div4 {
      grid-area: 4 / 1 / 4 / 1;
      &:after {
        height: 0 !important;
      }
    }
    .div5,
    .div6 {
      &:after {
        content: "";
        position: absolute;
        left: -11px !important;
        top: 0 !important;
        width: 1px !important;
        height: 100% !important;
        background-color: ${Color.greySeparator};
        ${breakpoints.darkMode} {
          [data-theme="dark"] & {
            background-color: ${ColorDark.greySeparator};
          }
        }
      }
    }
    .div5 {
      grid-area: 4 / 2 / 4 / 2;
    }
    .div6 {
      grid-area: 4 / 3 / 4 / 3;
      ${breakpoints.tablet} {
        &:after {
          width: 0 !important;
        }
      }
    }
    .banner-skeleton {
      position: relative !important;
      height: 980px;
      top: 0;
      ${breakpoints.phone} {
        position: relative !important;
        top: 0;
        height: 600px;
      }
    }
  }
  .banner-container {
    height: 980px;
    position: relative;
    background-color: ${Color.greySeparator};
    ${breakpoints.tablet} {
      height: 975px;
    }
  }
  .banner {
    margin-bottom: inherit;
    justify-content: center;
    ${breakpoints.phone} {
      min-height: 300px;
      align-items: center;
    }
  }
  ${breakpoints.phone} {
    max-height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    .div1 {
      order: 1;
    }
    .div2 {
      order: 2;
    }
    .div3 {
      order: 3;
    }
    .banner-container {
      order: 4;
    }
    .div4 {
      order: 5;
    }
    .div5 {
      order: 6;
    }
    .div6 {
      order: 7;
    }
  }
`;
export const Card = styled.div`
  ${cardMixin}
`;

export const InnerCardM = styled.div`
  ${innerMiddleMixin};
`;

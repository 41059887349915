import { BannerCajaGris } from "@/shared/ContentBanner";
import { ParentGrid } from "./style";
import { GenericNormalSkeleton, GenericVisualSkeleton } from "@/shared/Skeleton";

const Skeleton = () => {
  return (
    <ParentGrid className={`Container3PisosB module-grid`}>
      {[...Array(6)].map((_, index) => (
        <>
          {(index === 0) ? (
            <GenericVisualSkeleton classType={`div${index + 1}`} key={index} />
          ) : <>
            {index === 3 && (
              <BannerCajaGris heightBanner="600px" className={`banner sticky banner-skeleton`} />
            )}

            { index !== 0 && <GenericNormalSkeleton classType={`div${index + 1}`} key={index} />}
          </>}
        </>
      ))}
    </ParentGrid>
  );
};

export default Skeleton;
